import React from "react";

function Auth({GoogleLogin, login}){
  return (
    <div className="authScreen">
      <GoogleLogin
        onSuccess={login}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </div>
  );
}

export default Auth;