import React, { useState } from "react";
import axios from "axios";
import CompanyProfiles from "./CompanyProfiles";

function NameInput({
  apiLink,
  linkedInSearch,
  finstatSearch,
  growjoSearch,
  logout,
  scrapeProfile,
  loading,
  responseModal,
  dbObjId,
  companyLogo,
  dbSearch
}) {
  const [linkedInProfiles, setLinkedInProfiles] = useState([]);
  const [finstatProfiles, setFinstatProfiles] = useState([]);
  const [growjoProfiles, setGrowjoProfiles] = useState([]);
  const [modalState, setModalState] = useState("");

  const findLinkedInProfiles = () => {
    if (!linkedInSearch.linkedInSearch.title) {
      return false;
    }
    loading.setLoading(true);
    const linkProfilesReq = `${apiLink}/api/company/profiles/linkedin?title=${linkedInSearch.linkedInSearch.title}`;
    axios
      .get(linkProfilesReq)
      .then((res) => {
        console.log(res);
        loading.setLoading(false);
        setLinkedInProfiles(res.data);
        if (res.data.length) {
          setModalState("linkedin");
        } else {
          responseModal.setResponse({
            status: 404,
            message: "Nothing was found",
          });
        }
      })
      .catch((err) => {
        loading.setLoading(false);
        console.log(err);
      });
  };

  const findFinstatProfiles = () => {
    if(!finstatSearch.finstatSearch.title){
      return false;
    }
    loading.setLoading(true);
    const finstatSearchReq = `${apiLink}/api/company/profiles/finstat?title=${finstatSearch.finstatSearch.title}`;
    axios
      .get(finstatSearchReq)
      .then((res) => {
        console.log(res);
        loading.setLoading(false);
        setFinstatProfiles(res.data);
        if(res.data.length){
          setModalState("finstat");
        }else{
          responseModal.setResponse({status: 404, message: "Nothing was found"})
        }
      })
      .catch((err) => {
        loading.setLoading(false);
        console.log(err);
      })
  }

  const findGrowjoProfiles = () => {
    if (!growjoSearch.growjoSearch.title) {
      return false;
    }
    loading.setLoading(true);
    const finstatSearchReq = `${apiLink}/api/company/profiles/growjo?title=${growjoSearch.growjoSearch.title}`;
    axios
      .get(finstatSearchReq)
      .then((res) => {
        setGrowjoProfiles(res.data);
        if (res.data.length) {
          setModalState("growjo");
        } else {
          responseModal.setResponse({
            status: 404,
            message: "Nothing was found",
          });
        }
        loading.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        loading.setLoading(false);
      });
  };

  const updateTitle = (event) => {
    if (event.target.className === "linkedIn") {
      const newObject = { ...linkedInSearch.linkedInSearch };
      if (!event.target.value.length) {
        setLinkedInProfiles([]);
      }
      newObject["title"] = event.target.value;
      linkedInSearch.setLinkedInSearch(newObject);
    }else if(event.target.className === "finstat"){
      const newObject = { ...finstatSearch.finstatSearch };
      if (!event.target.value.length) {
        setFinstatProfiles([]);
      }
      newObject["title"] = event.target.value;
      finstatSearch.setFinstatSearch(newObject);
    }else if(event.target.className === "growjo"){
      const newObject = { ...growjoSearch.growjoSearch };
      if (!event.target.value.length) {
        setGrowjoProfiles([]);
      }
      newObject["title"] = event.target.value;
      growjoSearch.setGrowjoSearch(newObject);
    }
  };

  const removePreview = (event) => {
    dbObjId.setDbObjId("");
    if(event.target.className.split(" ")[0] === "linkedin"){
      const newObject = { ...linkedInSearch.linkedInSearch };
      newObject.profile = {};
      newObject.title = "";
      linkedInSearch.setLinkedInSearch(newObject);
    }else if (event.target.className.split(" ")[0] === "finstat") {
      const newObject = { ...finstatSearch.finstatSearch };
      newObject.profile = {};
      newObject.title = "";
      finstatSearch.setFinstatSearch(newObject);
    }else if (event.target.className.split(" ")[0] === "growjo"){
      const newObject = { ...growjoSearch.growjoSearch };
      newObject.profile = {};
      newObject.title = "";
      growjoSearch.setGrowjoSearch(newObject);
    }
  };

  const editPreview = (event) => {
    console.log("editing...");
    removePreview(event);
    if (event.target.className.split(" ")[0] === "linkedin") {
      setModalState("linkedin");
    } else if (event.target.className.split(" ")[0] === "finstat") {
      setModalState("finstat");
    } else if (event.target.className.split(" ")[0] === "growjo") {
      setModalState("growjo");
    }
  };

  const openDatabase = () => {
    dbSearch.setDbSearch(true);
  }

  return (
    <div className="input-name">
      {linkedInSearch.linkedInSearch.profile.title ? (
        <div className="search-preview">
          <img
            src={
              !linkedInSearch.linkedInSearch.profile.companyLogo
                ? "/default.png"
                : dbObjId.dbObjId
                ? URL.createObjectURL(companyLogo.companyLogo)
                : linkedInSearch.linkedInSearch.profile.companyLogo
            }
            alt="avatar"
          />
          <div className="profile-info">
            <h1>{linkedInSearch.linkedInSearch.profile.title}</h1>
            <p>
              <b>Link: </b>
              <a
                href={linkedInSearch.linkedInSearch.profile.linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                {linkedInSearch.linkedInSearch.profile.linkedInUrl}
              </a>
            </p>
            <p>
              <b>Position: </b>
              {linkedInSearch.linkedInSearch.profile.numOfEmpl}
            </p>
          </div>
          <div className="actions">
            <i
              className="linkedin fa fa-pencil-square-o"
              aria-hidden="true"
              onClick={editPreview}
            ></i>
            <i
              className="linkedin fa fa-trash"
              aria-hidden="true"
              onClick={removePreview}
            ></i>
          </div>
        </div>
      ) : (
        <div className="search">
          <img src="./linkedin.png" />
          <input
            type="text"
            value={linkedInSearch.linkedInSearch.title}
            className="linkedIn"
            onChange={(event) => updateTitle(event)}
            placeholder="Enter a title"
          />
          <i
            className={
              linkedInSearch.linkedInSearch.title
                ? `fa fa-search`
                : `fa fa-search disabled`
            }
            onClick={findLinkedInProfiles}
            aria-hidden="true"
          ></i>
        </div>
      )}
      {finstatSearch.finstatSearch.profile.finstatTitle ? (
        <div className="search-preview">
          <img src="./finstat.jpeg" />
          <div className="profile-info">
            <h1>{finstatSearch.finstatSearch.profile.finstatTitle}</h1>
            <p>
              <b>ICO: </b>
              {finstatSearch.finstatSearch.profile.ico}
            </p>
            <p>
              <b>Link: </b>
              <a href={finstatSearch.finstatSearch.profile.finstatUrl}>
                {finstatSearch.finstatSearch.profile.finstatUrl}
              </a>
            </p>
          </div>
          <div className="actions">
            <i
              className="finstat fa fa-pencil-square-o"
              aria-hidden="true"
              onClick={editPreview}
            ></i>
            <i
              className="finstat fa fa-trash"
              aria-hidden="true"
              onClick={removePreview}
            ></i>
          </div>
        </div>
      ) : (
        <div className="search">
          <img src="./finstat.jpeg" />
          <input
            type="text"
            value={finstatSearch.finstatSearch.title}
            className="finstat"
            onChange={(event) => updateTitle(event)}
            placeholder="Enter a title"
          />
          <i
            className={
              finstatSearch.finstatSearch.title
                ? `fa fa-search`
                : `fa fa-search disabled`
            }
            onClick={findFinstatProfiles}
            aria-hidden="true"
          ></i>
        </div>
      )}
      {growjoSearch.growjoSearch.profile.growjoTitle ? (
        <div className="search-preview">
          <img
            src={
              growjoSearch.growjoSearch.profile.growjoPicture
                ? growjoSearch.growjoSearch.profile.growjoPicture
                : "./growjo.png"
            }
          />
          <div className="profile-info">
            <h1>{growjoSearch.growjoSearch.profile.growjoTitle}</h1>
            <p>
              <b>Link: </b>
              <a
                href={growjoSearch.growjoSearch.profile.growjoUrl}
                target="_blank"
                rel="noreferrer"
              >
                {growjoSearch.growjoSearch.profile.growjoUrl}
              </a>
            </p>
            <p>
              <b>Snippet: </b>
              {growjoSearch.growjoSearch.profile.growjoSnippet}
            </p>
          </div>
          <div className="actions">
            <i
              className="growjo fa fa-pencil-square-o"
              aria-hidden="true"
              onClick={editPreview}
            ></i>
            <i
              className="growjo fa fa-trash"
              aria-hidden="true"
              onClick={removePreview}
            ></i>
          </div>
        </div>
      ) : (
        <div className="search">
          <img src="./growjo.png" />
          <input
            type="text"
            value={growjoSearch.growjoSearch.title}
            className="growjo"
            onChange={(event) => updateTitle(event)}
            placeholder="Enter a title"
          />
          <i
            className={
              growjoSearch.growjoSearch.title
                ? `fa fa-search`
                : `fa fa-search disabled`
            }
            onClick={findGrowjoProfiles}
            aria-hidden="true"
          ></i>
        </div>
      )}
      <CompanyProfiles
        modalState={{ modalState, setModalState }}
        profiles={
          modalState === "linkedin"
            ? linkedInProfiles
            : modalState === "finstat"
            ? finstatProfiles
            : growjoProfiles
        }
        linkedInSearch={linkedInSearch}
        finstatSearch={finstatSearch}
        growjoSearch={growjoSearch}
      />
      <div className="scrape-btn" onClick={scrapeProfile}>
        <div>
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
        Scrape
      </div>
      <div className="scrape-btn" onClick={openDatabase}>
        <div>
          <i className="fa fa-database" aria-hidden="true"></i>
        </div>
        Database
      </div>
    </div>
  );
}

export default NameInput;
