import React, { useState } from "react";
import Moment from "moment";
import { marked } from "marked";
import "./pdf.css";

export const TemplateCompany = React.forwardRef((props, ref) => {

  return (
    <div ref={ref} className="pdf">
      <div className="pdf-header">
        <h1>{props.data.title}</h1>
        <img
          src={
            typeof props.data.companyLogo === "object"
              ? URL.createObjectURL(props.data.companyLogo)
              : props.data.companyLogo
          }
          alt="avatar"
        />
      </div>
      <table className="pdf-main">
        <tr>
          <td className="pdf-basic">
            <div>
              <h2>Basic overview</h2>
              <ul>
                <li>
                  <b>Year of foundation</b>: {props.data.foundedDate}
                </li>
                <li>
                  <b>Headquarter</b>: {props.data.headquarters}
                </li>
                <li>
                  <b>Number of employees</b>: {props.data.numOfEmpl}
                </li>
                <li>
                  <b>Industry</b>: {props.data.industries}
                </li>
              </ul>
            </div>
          </td>
          <td className="pdf-education">
            <div>
              <h2>Financial data:</h2>
              <ul>
                {props.data.growjoUrl ? (
                  <li>
                    <a href={props.data.growjoUrl} target="_blank">
                      Growjo Url
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {props.data.finstatUrl ? (
                  <li>
                    <a href={props.data.finstatUrl} target="_blank">
                      Finstat Url
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <b>Date of statistics</b>: {props.data.statsDate}
                </li>
                <li>
                  <b>Revenue</b>: {props.data.revenue}
                </li>
                <li>
                  <b>Valuation</b>: {props.data.valuation}
                </li>
                <li>
                  {props.data.profit ? (
                    <>
                      <b>Profit</b>: {props.data.profit}
                    </>
                  ) : (
                    <>
                      <b>Loss</b>: {props.data.loss}
                    </>
                  )}
                </li>
                <li>
                  <b>Assets</b>: {props.data.assets}
                </li>
                <li>
                  <b>Capital</b>: {props.data.capital}
                </li>
                <li>
                  <b>Debt</b>: {props.data.debt}
                </li>
                <li>
                  <b>Margin</b>: {props.data.margin}
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td className="pdf-top-empl">
            <div>
              <h2>Key people:</h2>
              <ul>
                {props.data.topEmpl.map((empl, key) => {
                  return (
                    <li key={key}>
                      <a href={empl.url} target="_blank">
                        {empl.name}
                      </a>{" "}
                      - {empl.occupation}
                    </li>
                  );
                })}
              </ul>
            </div>
          </td>
          <td className="pdf-socials">
            <div>
              <h2>Social media:</h2>
              <ul>
                <li>
                  <a href={props.data.linkedInUrl} target="_blank">
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td className="pdf-facts">
            <div>
              <h2>Product details</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parse(props.data.productDetails),
                }}
              ></div>
            </div>
          </td>
          <td className="pdf-activity">
            <div>
              <h2>Activity</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parse(props.data.activity),
                }}
              ></div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="pdf-observations" colSpan={2}>
            <div>
              <h2>Observations</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parse(props.data.observations),
                }}
              ></div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="pdf-contacts" colSpan={2}>
            <h2>Contacts | </h2>
            <b>email:</b> <a href={`mailto: ${props.data.email}`} target="_blank">{props.data.email}</a>&nbsp;&nbsp;
            <b>phone:</b> {props.data.phone}&nbsp;&nbsp;
            <b>website:</b> <a href={props.data.website} target="_blank">{props.data.website}</a>
          </td>
        </tr>
      </table>
      <div className="pdf-footer">
        SCALEUP | Person Onesheeter - {props.data.name} -{" "}
        <b>person overview |</b> Last edit: {Moment().format("Do MMMM YY")} |{" "}
        {"DSO"}
      </div>
    </div>
  );
});
