import React, { useRef, useState } from "react";
import { Link, Routes, Route, useNavigate, useParams } from "react-router-dom";
import ArrayInput from "./ArrayInput.js";
import MarkedInput from "./MarkdownInput.js";
import { useReactToPrint } from "react-to-print";
import { TemplateCompany } from "./TemplateCompany.js";
import { marked } from "marked";

function CompanyDataForm({
  dbObjId, 
  companyLogo, 
  title, 
  linkedInUrl,
  growjoUrl, 
  finstatUrl,
  foundedDate,
  about,
  website,
  headquarters,
  industries,
  numOfEmpl,
  statsDate,
  revenue,
  valuation,
  loss,
  profit,
  assets,
  capital,
  debt,
  margin,
  establishment,
  topEmpl,
  productDetails,
  activity,
  observations,
  email,
  phone,
  uploadProfile,
  deleteProfile
 }) {
  const [pdfData, setPdfData] = useState({
    title: title.title,
    companyLogo: companyLogo.companyLogo,
    linkedInUrl: linkedInUrl.linkedInUrl,
    growjoUrl: growjoUrl.growjoUrl,
    foundedDate: foundedDate.foundedDate,
    about: about.about,
    website: website.website,
    headquarters: headquarters.headquarters,
    industries: industries.industries,
    numOfEmpl: numOfEmpl.numOfEmpl,
    statsDate: statsDate.statsDate,
    revenue: revenue.revenue,
    valuation: valuation.valuation,
    loss: loss.loss,
    profit: profit.profit,
    assets: assets.assets,
    capital: capital.capital,
    debt: debt.debt,
    margin: margin.margin,
    establishment: establishment.establishment,
    topEmpl: topEmpl.objects,
    productDetails: marked.parse(productDetails.markdown),
    activity: marked.parse(activity.markdown),
    observations: marked.parse(observations.markdown),
    email: email.email,
    phone: phone.phone
  });

  const componentRef = useRef();

  const print = async () => {
    const newPdfData = {
      title: title.title,
      companyLogo: companyLogo.companyLogo,
      linkedInUrl: linkedInUrl.linkedInUrl,
      growjoUrl: growjoUrl.growjoUrl,
      finstatUrl: finstatUrl.finstatUrl,
      foundedDate: foundedDate.foundedDate,
      about: about.about,
      website: website.website,
      headquarters: headquarters.headquarters,
      industries: industries.industries,
      numOfEmpl: numOfEmpl.numOfEmpl,
      statsDate: statsDate.statsDate,
      revenue: revenue.revenue,
      valuation: valuation.valuation,
      loss: loss.loss,
      profit: profit.profit,
      assets: assets.assets,
      capital: capital.capital,
      debt: debt.debt,
      margin: margin.margin,
      establishment: establishment.establishment,
      topEmpl: topEmpl.objects.filter((empl) => empl.addToFinal),
      productDetails: marked.parse(productDetails.markdown),
      activity: marked.parse(activity.markdown),
      observations: marked.parse(observations.markdown),
      email: email.email,
      phone: phone.phone
    };
    console.log(newPdfData);
    await setPdfData(newPdfData);
    handlePrint();
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="scraped data">
      <label>Profile picture</label>
      <img
        src={
          typeof companyLogo.companyLogo === "object"
            ? URL.createObjectURL(companyLogo.companyLogo)
            : companyLogo.companyLogo
        }
        className="profile-picture"
        alt="Profile avatar"
      />
      <input type="file" onChange={companyLogo.updateCompanyLogo} />

      <label>Company Title</label>
      <input
        type="text"
        value={title.title}
        onChange={(event) => title.setTitle(event.target.value)}
      />

      <label>LinkedIn URL</label>
      <input
        type="text"
        value={linkedInUrl.linkedInUrl}
        onChange={(event) => linkedInUrl.setLinkedInUrl(event.target.value)}
      />

      <label>Finstat URL</label>
      <input
        type="text"
        value={finstatUrl.finstatUrl}
        onChange={(event) => finstatUrl.setFinstatUrl(event.target.value)}
      />

      <label>Growjo URL</label>
      <input
        type="text"
        value={growjoUrl.growjoUrl}
        onChange={(event) => growjoUrl.setGrowjoUrl(event.target.value)}
      />

      <label>Number of employees:</label>
      <input
        type="text"
        value={numOfEmpl.numOfEmpl}
        onChange={(event) => numOfEmpl.setNumOfEmpl(event.target.value)}
      />

      <label>Founded Date:</label>
      <input
        type="text"
        value={foundedDate.foundedDate}
        onChange={(event) => foundedDate.setFoundedDate(event.target.value)}
      />

      <label>About:</label>
      <textarea
        type="text"
        value={about.about}
        onChange={(event) => about.setAbout(event.target.value)}
      ></textarea>

      <label>Website:</label>
      <input
        type="text"
        value={website.website}
        onChange={(event) => website.setWebsite(event.target.value)}
      />

      <label>Headquarters:</label>
      <input
        type="text"
        value={headquarters.headquarters}
        onChange={(event) => headquarters.setHeadquarters(event.target.value)}
      />

      <label>Industries:</label>
      <input
        type="text"
        value={industries.industries}
        onChange={(event) => industries.setIndustries(event.target.value)}
      />

      <label>Establishment:</label>
      <input
        type="text"
        value={establishment.establishment}
        onChange={(event) => establishment.setEstablishment(event.target.value)}
      />

      <fieldset>
        <figcaption>Stats</figcaption>

        <label>Date of statistics:</label>
        <input
          type="text"
          value={statsDate.statsDate}
          onChange={(event) => statsDate.setStatesDate(event.target.value)}
        />

        <label>Revenue:</label>
        <input
          type="text"
          value={revenue.revenue}
          onChange={(event) => revenue.setRevenue(event.target.value)}
        />

        <label>Valuation:</label>
        <input
          type="text"
          value={valuation.valuation}
          onChange={(event) => valuation.setValuation(event.target.value)}
        />

        <label>Loss:</label>
        <input
          type="text"
          value={loss.loss}
          onChange={(event) => loss.setLoss(event.target.value)}
        />

        <label>Profit:</label>
        <input
          type="text"
          value={profit.profit}
          onChange={(event) => profit.setProfit(event.target.value)}
        />

        <label>Assets:</label>
        <input
          type="text"
          value={assets.assets}
          onChange={(event) => assets.setAssets(event.target.value)}
        />

        <label>Capital:</label>
        <input
          type="text"
          value={capital.capital}
          onChange={(event) => capital.setCapital(event.target.value)}
        />

        <label>Debt:</label>
        <input
          type="text"
          value={debt.debt}
          onChange={(event) => debt.setDebt(event.target.value)}
        />

        <label>Margin:</label>
        <input
          type="text"
          value={margin.margin}
          onChange={(event) => margin.setMargin(event.target.value)}
        />
      </fieldset>

      <label>Product details:</label>
      <MarkedInput markdown={productDetails} />

      <label>Activity:</label>
      <MarkedInput markdown={activity} />

      <label>Observations:</label>
      <MarkedInput markdown={observations} />

      <label>Email:</label>
      <input
        type="text"
        value={email.email}
        onChange={(event) => email.setEmail(event.target.value)}
      />

      <label>Phone:</label>
      <input
        type="text"
        value={phone.phone}
        onChange={(event) => phone.setPhone(event.target.value)}
      />

      {topEmpl.objects ? (
        <fieldset>
          <label>Top employees:</label>
          <ArrayInput
            entity={topEmpl}
            emptyObj={{
              name: "",
              occupation: "",
              url: "",
              addToFinal: true,
            }}
            keys={["name", "occupation", "url"]}
            placeholders={["Name", "Ocupation", "LinkedIn url"]}
          />
        </fieldset>
      ) : (
        ""
      )}

      <div style={{ display: "none" }}>
        <TemplateCompany ref={componentRef} data={pdfData} />
      </div>

      <button className="sumbit-profile" onClick={uploadProfile}>
        {dbObjId.dbObjId ? "Update profile" : "Create profile"}
      </button>
      {dbObjId.dbObjId ? (
        <button className="delete-profile" onClick={deleteProfile}>
          Delete profile
        </button>
      ) : (
        ""
      )}
      <button className="sumbit-profile" onClick={print}>
        To PDF
      </button>
    </div>
  );
}

export default CompanyDataForm;
