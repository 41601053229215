import React, {useState, useEffect} from "react";
import {GoogleOAuthProvider, GoogleLogin, googleLogout } from "@react-oauth/google";
import MainPage from "./MainPage";
import Person from "./Person";
import Company from "./Company";
import CompanyDataForm from "./CompanyDataForm";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from "axios";
import Auth from "./Auth.js";
import EditUsers from "./EditUsers";

// const apiLink = "http://localhost:8080"
// const apiLink = "https://scaleup-1sheet.loca.lt";
// const apiLink = "http://34.118.65.12";
const apiLink = "https://scaleup.support";

function App() {
  const [user, setUser] = useState();
  const [response, setResponse] = useState("");

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (!user && jwtToken) {
      const profileUploadReq = `${apiLink}/api/auth`;
      axios
        .post(profileUploadReq, { jwtToken: jwtToken })
        .then(function (response) {
          setUser(response.data);
        })
        .catch(function (error) {
          console.log(error);
          error.message = "You don't have access :(";
          setResponse(error);
          localStorage.clear();
          googleLogout();
        });
    }
  });

  const login = async (res) => {
    const profileUploadReq = `${apiLink}/api/auth`;
    axios
      .post(profileUploadReq, { jwtToken: res.credential })
      .then(function (response) {
        console.log(response);
        setUser(response.data);
        localStorage.setItem("jwtToken", res.credential);
        axios.defaults.headers.common["x-auth-token"] = res.credential;
      })
      .catch(function (error) {
        console.log(error);
        error.message = "You don't have access :(";
        setResponse(error);
      });
  };

  const logout = async () => {
    setUser();
    localStorage.clear();
    googleLogout();
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage logout={logout} user={user} />,
    },
    {
      path: "person",
      element: <Person apiLink={apiLink} user={user} />,
    },
    {
      path: "company",
      element: <Company apiLink={apiLink}/>,
      children: [
        {
          path: "profile",
          element: <CompanyDataForm user={user}/>,
        },
      ],
    },
    {
      path: "users",
      element: <EditUsers apiLink={apiLink}/>
    },
  ]);
  

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      {!user ? (
        <Auth GoogleLogin={GoogleLogin} login={login} />
      ) : (
        <RouterProvider router={router} />
      )}
    </GoogleOAuthProvider>
  );
}

export default App;
