import React, { useState, useEffect } from "react";
import NameInput from "./NameInput.js";
import DataForm from "./DataForm.js";
import ResponseModal from "./ResponseModal.js";
import EditUsers from "./EditUsers.js";
import Loading from "./Loading.js";
import axios from "axios";
import Modal from "react-modal";
import DatabaseProfiles from "./DatabaseProfiles.js";


function Person({apiLink, user}) {
  const [linkedInSearch, setLinkedInSearch] = useState({
    name: "",
    indx: "",
    profile: {},
  });
  const [twitterSearch, setTwitterSearch] = useState({
    name: "",
    indx: "",
    profile: {},
  });
  const [dbSearch, setDbSearch] = useState(false);


  const [dbObjId, setDbObjId] = useState("");
  const [name, setName] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [location, setLocation] = useState("");
  const [education, setEducation] = useState([]);
  const [skills, setSkills] = useState([]);
  const [briefIntro, setIntro] = useState("");
  const [languages, setLanguages] = useState([]);
  const [picture, setPicture] = useState("");
  const [experience, setExperience] = useState([]);
  const [totalExp, setTotalExp] = useState("");
  const [profExp, setProfExp] = useState("");
  const [industry, setIndustry] = useState("");
  const [specialties, setSpecialties] = useState("");
  const [age, setAge] = useState("");
  const [facts, setFacts] = useState("");
  const [activity, setActivity] = useState("");
  const [twitterFollowers, setTwitterFollowers] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [editUsers, setEditUsers] = useState(false);

  useEffect(() => {
    let lastDates = [];
    let total = 0;
    for (let i in experience) {
      const endDate = experience[i].endDate
        ? new Date(experience[i].endDate)
        : new Date();
      const dates = [new Date(experience[i].startDate), endDate];
      if (lastDates.length === 0) {
        total = Math.round((dates[1] - dates[0]) / (1000 * 60 * 60 * 24));
        lastDates[0] = dates[0];
        lastDates[1] = dates[1];
      } else if (lastDates[0] > dates[1]) {
        total += Math.round((dates[1] - dates[0]) / (1000 * 60 * 60 * 24));
        lastDates[0] = dates[0];
        lastDates[1] = dates[1];
      } else if (lastDates[0] <= dates[1] && lastDates[0] > dates[0]) {
        total -= Math.round(
          (lastDates[1] - lastDates[0]) / (1000 * 60 * 60 * 24)
        );
        total += Math.round((lastDates[1] - dates[0]) / (1000 * 60 * 60 * 24));
        lastDates[0] = dates[0];
      }
    }
    setTotalExp((total / 365).toFixed(1));
  }, [experience.length]);

  const handleBackMain = async () => {
    await resetProfile();
    setResponse("");
    setEditUsers(false);
  };

  const updatePicture = (event) => {
    setPicture(event.target.files[0]);
  };

  const toDataUrl = (url, name) => {
    if (!url) {
      url = "/default.png";
    }
    fetch(url)
      .then((response) => {
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        let format = blob.type;
        format = format.split("/");
        format = format[format.length - 1];
        const profilePicture = new File([blob], `${name}.${format}`, {
          type: blob.type,
        });
        setPicture(profilePicture);
      });
  };

  const scrapeProfile = (dbProfile) => {
    setLoading(true);
    if (!linkedInSearch.profile._id && !dbProfile._id) {
      const linkReq = `${apiLink}/api/person?name=${linkedInSearch.name}&indx=${linkedInSearch.indx}`;
      axios
        .get(linkReq)
        .then((res) => {
          console.log(res.data);
          setDbObjId("");
          setName(res.data.name);
          setLocation(res.data.location);
          setLinkedInUrl(res.data.linkedInUrl);
          setEducation(res.data.education);
          setSkills(res.data.skills);
          setIntro(res.data.briefIntro);
          setLanguages(res.data.languages);
          setExperience(res.data.experience);
          toDataUrl(res.data.picture, res.data.name);
          setTwitterFollowers(twitterSearch.profile.twitterFollowers);
          setTwitterUrl(twitterSearch.profile.twitterUrl);
          setTwitterUsername(twitterSearch.profile.twitterUsername);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if(dbProfile._id){
      console.log("Uploading from dbProfile");
      console.log(dbProfile);
      setDbObjId(dbProfile._id);
      setName(dbProfile.name);
      setLinkedInUrl(dbProfile.linkedInUrl);
      if (dbProfile.education){
        setEducation(dbProfile.education);
      }
      if (dbProfile.skills){
        setSkills(dbProfile.skills);
      }
      setIntro(
        dbProfile.briefIntro != "null" && dbProfile.briefIntro != "undefined"
          ? dbProfile.briefIntro
          : ""
      );
      if (dbProfile.languages){
        setLanguages(dbProfile.languages);
      }
      if (dbProfile.experience){
        setExperience(dbProfile.experience);
      }
      setPicture(dbProfile.picture);
      setIndustry(dbProfile.industry);
      setAge(dbProfile.age);
      setProfExp(dbProfile.profExp);
      setSpecialties(dbProfile.specialties);
      setFacts(dbProfile.facts);
      setActivity(dbProfile.activity);
      setLocation(dbProfile.location);
      setPhone(dbProfile.phone);
      setEmail(dbProfile.email);
      setTwitterFollowers(dbProfile.twitterFollowers != "null" && dbProfile.twitterFollowers != "undefined"  ? dbProfile.twitterFollowers : "");
      setTwitterUrl(
        dbProfile.twitterUrl != "null" && dbProfile.twitterUrl != "undefined"
          ? dbProfile.twitterUrl
          : ""
      );
      setTwitterUsername(
        dbProfile.twitterUsername != "null" &&
          dbProfile.twitterUsername != "undefined"
          ? dbProfile.twitterUsername
          : ""
      );
      setLoading(false);
    }else {
      console.log("Uploading from linkedInSearch");
      console.log(linkedInSearch.profile);
      setDbObjId(linkedInSearch.profile._id);
      setName(linkedInSearch.profile.name);
      setLinkedInUrl(linkedInSearch.profile.linkedInUrl);
      setEducation(linkedInSearch.profile.education);
      setSkills(linkedInSearch.profile.skills);
      setIntro(
        linkedInSearch.profile.briefIntro != "null" &&
          linkedInSearch.profile.briefIntro != "undefined"
          ? linkedInSearch.profile.briefIntro
          : ""
      );
      setLanguages(linkedInSearch.profile.languages);
      setExperience(linkedInSearch.profile.experience);
      setPicture(linkedInSearch.profile.picture);
      setIndustry(linkedInSearch.profile.industry);
      setAge(linkedInSearch.profile.age);
      setProfExp(linkedInSearch.profile.profExp);
      setSpecialties(linkedInSearch.profile.specialties);
      setFacts(linkedInSearch.profile.facts);
      setActivity(linkedInSearch.profile.activity);
      setLocation(linkedInSearch.profile.location);
      setPhone(linkedInSearch.profile.phone);
      setEmail(linkedInSearch.profile.email);
      setTwitterFollowers(
        twitterSearch.profile.twitterFollowers != "null" &&
          twitterSearch.profile.twitterFollowers != "undefined"
          ? twitterSearch.profile.twitterFollowers
          : ""
      );
      setTwitterUrl(
        twitterSearch.profile.twitterUrl != "null" &&
          twitterSearch.profile.twitterUrl != "undefined"
          ? twitterSearch.profile.twitterUrl
          : ""
      );
      setTwitterUsername(
        twitterSearch.profile.twitterUsername != "null" &&
          twitterSearch.profile.twitterUsername != "undefined"
          ? twitterSearch.profile.twitterUsername
          : ""
      );
      setLoading(false);
    }
  };

  const uploadProfile = () => {
    setLoading(true);
    const profileUploadReq = `${apiLink}/api/person/profiles`;
    const bodyFormData = new FormData();
    bodyFormData.append("id", dbObjId);
    bodyFormData.append("name", name);
    bodyFormData.append("linkedInUrl", linkedInUrl);
    bodyFormData.append(
      "education",
      JSON.stringify(education.map((education) => JSON.stringify(education)))
    );
    bodyFormData.append(
      "skills",
      JSON.stringify(skills.map((skill) => JSON.stringify(skill)))
    );
    bodyFormData.append("briefIntro", briefIntro);
    bodyFormData.append(
      "languages",
      JSON.stringify(languages.map((language) => JSON.stringify(language)))
    );
    bodyFormData.append(
      "experience",
      JSON.stringify(experience.map((experience) => JSON.stringify(experience)))
    );
    bodyFormData.append("totalExp", totalExp);
    bodyFormData.append("picture", picture);
    bodyFormData.append("location", location);
    bodyFormData.append("profExp", profExp);
    bodyFormData.append("industry", industry);
    bodyFormData.append("specialties", specialties);
    bodyFormData.append("age", age);
    bodyFormData.append("facts", facts);
    bodyFormData.append("activity", activity);
    bodyFormData.append("twitterFollowers", twitterFollowers);
    bodyFormData.append("twitterUrl", twitterUrl);
    bodyFormData.append("phone", phone);
    bodyFormData.append("email", email);
    console.log(bodyFormData);
    axios
      .post(profileUploadReq, bodyFormData, {})
      .then(function (response) {
        console.log(response);
        response["action"] = dbObjId ? "updated" : "created";
        setLoading(false);
        setResponse(response);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setResponse(error);
      });
  };

  const deleteProfile = async (event) => {
    console.log("deleting...");
    let id = "";
    if (dbObjId) {
      id = dbObjId;
    } else {
      const classes = event.target.className.split(" ");
      id = classes[0];
    }
    const deleteLink = `${apiLink}/api/person/profiles?id=${id}`;
    await axios
      .delete(deleteLink)
      .then((res) => {
        console.log(res);
        if(dbObjId){
          res["action"] = "deleted";
          setResponse(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const resetProfile = async () => {
    setName("");
    setLinkedInUrl("");
    setLocation("");
    setEducation([]);
    setSkills([]);
    setIntro("");
    setLanguages([]);
    setPicture("");
    setExperience([]);
    setTotalExp("");
    setProfExp("");
    setIndustry("");
    setSpecialties("");
    setAge("");
    setFacts("");
    setActivity("");
  };

  return (
    <div id="main">
      <div className="btns">
        {picture ? (
          <>
            <button onClick={handleBackMain}>Main</button>
          </>
        ) : (
          ""
        )}
      </div>
      {picture ? (
        <DataForm
          dbObjId={dbObjId}
          linkedInUrl={{ linkedInUrl, setLinkedInUrl }}
          name={{ name, setName }}
          age={{ age, setAge }}
          picture={{ picture, updatePicture }}
          briefIntro={{ briefIntro, setIntro }}
          education={{ objects: education, setObjects: setEducation }}
          languages={{ objects: languages, setObjects: setLanguages }}
          skills={{ objects: skills, setObjects: setSkills }}
          experience={{ objects: experience, setObjects: setExperience }}
          totalExp={{ totalExp, setTotalExp }}
          uploadProfile={uploadProfile}
          location={{ location, setLocation }}
          profExp={{ profExp, setProfExp }}
          industry={{ industry, setIndustry }}
          specialties={{ specialties, setSpecialties }}
          facts={{ markdown: facts, setMarkdown: setFacts }}
          activity={{ markdown: activity, setMarkdown: setActivity }}
          twitterUrl={{ twitterUrl, setTwitterUrl }}
          twitterFollowers={{ twitterFollowers, setTwitterFollowers }}
          email={{email, setEmail}}
          phone={{phone, setPhone}}
          deleteProfile={deleteProfile}
          user={user}
        ></DataForm>
      ) : dbSearch ? (
        <DatabaseProfiles
          type={"personal"}
          dbSearch={{ dbSearch, setDbSearch }}
          apiLink={apiLink}
          deleteProfile={deleteProfile}
          scrapeProfile={scrapeProfile}
        />
      ) : (
        <NameInput
          apiLink={{ apiLink }}
          linkedInSearch={{ linkedInSearch, setLinkedInSearch }}
          twitterSearch={{ twitterSearch, setTwitterSearch }}
          scrapeProfile={ scrapeProfile }
          loading={{ loading, setLoading }}
          dbSearch={{ dbSearch, setDbSearch }}
        ></NameInput>
      )}
      {response ? (
        <ResponseModal
          response={{ response, setResponse }}
          handleBackMain={handleBackMain}
        />
      ) : (
        ""
      )}
      {loading ? <Loading loading={{ loading, setLoading }} /> : ""}
    </div>
  );
}

export default Person;
