import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement("#root");

function Profiles({modalState, profiles, linkedInSearch, twitterSearch}){
  const closeModal = () =>{
    modalState.setModalState(false);
  }

  const chooseProfile = (event) => {
    if(!event.target.target || event.target.target !== "_blank"){
      let profileIndx = event.target
        .closest(".profile")
        .className.split(" ")[0];
      if(modalState.modalState === "linkedin"){
        const linkedinObject = { ...linkedInSearch.linkedInSearch };
        linkedinObject["profile"] = { ...profiles[profileIndx] };
        if (!profiles[profileIndx]._id) {
          let dbProfilesCounter = profiles.filter(
            (profile) => profile._id
          ).length;
          profileIndx -= dbProfilesCounter;
        }else{
          const twitterObject = {...twitterSearch.twitterSearch};
          twitterObject["profile"]["twitterName"] = profiles[profileIndx]["name"];
          twitterObject["profile"]["twitterFollowers"] = profiles[profileIndx]["twitterFollowers"];
          twitterObject["profile"]["twitterUrl"] = profiles[profileIndx]["twitterUrl"];
          twitterObject["profile"]["twitterUsername"] = profiles[profileIndx]["twitterUsername"];
          console.log(twitterObject);
          twitterSearch.setTwitterSearch(twitterObject);
        }
        linkedinObject["indx"] = profileIndx;
        console.log(linkedinObject);
        linkedInSearch.setLinkedInSearch(linkedinObject);
      }else{
        const newObject = {...twitterSearch.twitterSearch};
        newObject["profile"] = {...profiles[profileIndx]};
        newObject["indx"] = profileIndx;
        twitterSearch.setTwitterSearch(newObject);
      } 
      modalState.setModalState("");   
    }
  }

  return (
    <Modal isOpen={modalState.modalState ? true : false} onRequestClose={closeModal} className="modal" overlayClassName="overlay">
      <i className="fa fa-times" aria-hidden="true" onClick={closeModal}></i>
      <div className="profiles">
        {profiles.map((profile, indx) => {
          if(profile.linkedInUrl){
            const position = profile.position
              ? profile.position
              : profile.experience && profile.experience[0]
              ? `${profile.experience[0].title} - ${profile.experience[0].companyName}`
              : "";
            return (
              <div
                key={indx}
                className={
                  profile._id ? `${indx} existing profile` : `${indx} profile`
                }
                onClick={chooseProfile}
              >
                <div className="profile-image">
                  <img
                    src={profile.picture ? profile.picture : "/default.png"}
                    alt="avatar"
                  />
                </div>
                <div className="profile-info">
                  <h1>{profile.name}</h1>
                  <p>
                    <b>Link:</b>
                    <a
                      href={profile.linkedInUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {profile.linkedInUrl}
                    </a>
                  </p>
                  <p>
                    <b>Position:</b>
                    {position}
                  </p>
                </div>
              </div>
            );
          }else{
            return (
              <div
                key={indx}
                className={`${indx} profile`}
                onClick={chooseProfile}
              >
                <div className="profile-image">
                  <img
                    src={
                      profile.twitterPicture
                        ? profile.twitterPicture
                        : "/default.png"
                    }
                    alt="avatar"
                  />
                </div>
                <div className="profile-info">
                  <h1>{profile.twitterName}</h1>
                  <p>
                    <b>Username:</b>
                    {profile.twitterUsername}
                  </p>
                  <p>
                    <b>Link:</b>
                    <a
                      href={profile.twitterUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {profile.twitterUrl}
                    </a>
                  </p>
                  <p>
                    <b>Followers:</b>
                    {profile.twitterFollowers}
                  </p>
                </div>
              </div>
            );
          }
        })}
      </div>
    </Modal>
  );
}

export default Profiles;

