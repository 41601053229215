import React, {useState, useEffect} from "react";
import axios from "axios";

function EditUsers({apiLink, setResponse}){
  const [users, setUsers] = useState("");
  const [newUser, setNewUser] = useState({email: "", admin: false});
  const linkReq = `${apiLink}/api/users`;

  useEffect((users) => {
    if(!users){
      axios.get(linkReq)
        .then((res)=>{
          setUsers(res.data);
        })
        .catch((error)=>{
          setResponse(error);
        })
    }
  }, [users])

  const updateNewUser = (event) => {
    const currentUser = newUser;
    if(event.target.id && event.target.id === "admin"){
      currentUser["admin"] = !currentUser["admin"];
    }else{
      currentUser["email"] = event.target.value;
    }
    setNewUser(currentUser);
  }

  const addNewUser = () => {
    axios.post(linkReq, {email: newUser.email, admin: newUser.admin})
      .then((res)=>{
        console.log(res);
      })
      .catch((err)=>{
        console.log(err);
      })
    setNewUser({name: "", admin: false});
    setUsers("");
  }

  const deleteUser = (event) => {
    const userId = event.target.className.split(" ")[0];
    const deleteLink = `${linkReq}?id=${userId}`;
    axios.delete(deleteLink)
      .then((res)=>{
        console.log(res);
      })
      .catch((err)=>{
        console.log(err);
      })
    setUsers("");
  }

  
  return (
    <>
      {users ? (
        <div>
          <div className="users">
            {users.map((user, i) => {
              return (
                <div key={i} className="user">
                  <p>{user.email}</p>
                  <i
                    className={`${user._id} fa fa-trash`}
                    onClick={deleteUser}
                    aria-hidden="true"
                  ></i>
                </div>
              );
            })}
          </div>
          <div>
            <input
              type="email"
              value={newUser.email}
              onChange={(event) => updateNewUser(event)}
            />
            <label>
              Grant admin rights:
              <input
                id="admin"
                type="checkbox"
                checked={newUser.admin}
                onChange={(event) => updateNewUser(event)}
              ></input>
            </label>
            <button className="addUser" onClick={addNewUser}>
              Add new user
            </button>
          </div>
        </div>
      ) : (
        <p>No users found</p>
      )}
    </>
  );
}

export default EditUsers;