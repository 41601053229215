import React from "react";
import { Link } from "react-router-dom";

function MainPage({logout, user}){
  return (
    <div className="main-page">
      <h1>Choose your template</h1>
      <div className="templates">
        <Link to={`company`} className="template" id="company">
          <i className="fa fa-building-o" aria-hidden="true"></i>
          <p>Company</p>
        </Link>
        <Link to={`person`} className="template">
          <i className="fa fa-user" aria-hidden="true"></i>
          <p>Person</p>
        </Link>
      </div>
      <div className="main-btns">
        <button onClick={logout}>
          Logout
        </button>
        {user.admin ? (
          <Link to={`users`} className="edit-users">Edit Users</Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default MainPage;