import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function CompanyProfiles({modalState, profiles, linkedInSearch, finstatSearch, growjoSearch}){
  const closeModal = () => {
    modalState.setModalState(false);
  }

  const chooseProfile = (event) => {
    if(!event.target.target || event.target.target !== "_blank"){
      let profileIndx = event.target
        .closest(".profile")
        .className.split(" ")[0];
      if (modalState.modalState === "linkedin") {
        const linkedinObject = { ...linkedInSearch.linkedInSearch };
        linkedinObject["profile"] = { ...profiles[profileIndx] };
        if (!profiles[profileIndx]._id) {
          let dbProfilesCounter = profiles.filter(
            (profile) => profile._id
          ).length;
          profileIndx -= dbProfilesCounter;
        }
        else{
          console.log("GROWJO PROFILE");
          console.log(profiles[profileIndx]);
          if(profiles[profileIndx]["growjoUrl"]){
            const growjoObject = {...growjoSearch.growjoSearch};
            growjoObject["profile"]["growjoTitle"] =
              profiles[profileIndx]["title"];
            growjoObject["profile"]["growjoUrl"] = profiles[profileIndx]["growjoUrl"];
            growjoObject["profile"]["growjoSnippet"] =
              profiles[profileIndx]["growjoSnippet"];
            growjoSearch.setGrowjoSearch(growjoObject);
          }
          if (profiles[profileIndx]["finstatUrl"]) {
            const finstatObject = {... finstatSearch.finstatSearch};
            finstatObject["profile"]["finstatTitle"] = profiles[profileIndx]["title"];
            const urlParts = profiles[profileIndx]["finstatUrl"].split("/");
            const ico = urlParts[urlParts.length - 1];
            finstatObject["profile"]["ico"] = ico;
            finstatObject["profile"]["finstatUrl"] = profiles[profileIndx]["finstatUrl"];
            console.log(finstatObject);
            finstatSearch.setFinstatSearch(finstatObject);
          }
        }
        linkedinObject["indx"] = profileIndx;
        linkedInSearch.setLinkedInSearch(linkedinObject);
      }else if(modalState.modalState === "finstat"){
        const finstatObject = { ...finstatSearch.finstatSearch };
        finstatObject["profile"] = { ...profiles[profileIndx] };
        finstatObject["indx"] = profileIndx;
        finstatSearch.setFinstatSearch(finstatObject);
      }else if(modalState.modalState === "growjo"){
        const growjoObject = {...growjoSearch.growjoSearch};
        growjoObject["profile"] = {...profiles[profileIndx]};
        growjoObject["indx"] = profileIndx;
        growjoSearch.setGrowjoSearch(growjoObject);
      }
    }
    modalState.setModalState("");
  }

  return (
    <Modal
      isOpen={modalState.modalState ? true : false}
      onRequestClose={closeModal}
      className="modal"
      overlayClassName="overlay"
    >
      <i className="fa fa-times" aria-hidden="true" onClick={closeModal}></i>
      <div className="profiles">
        {profiles.map((profile, indx) => {
          if(profile.linkedInUrl){
            return (
              <div
                key={indx}
                className={
                  profile._id ? `${indx} existing profile` : `${indx} profile`
                }
                onClick={chooseProfile}
              >
                <div className="profile-image">
                  <img
                    src={profile.companyLogo ? profile.companyLogo : "/default.png"}
                    alt="avatar"
                  />
                </div>
                <div className="profile-info">
                  <h1>{profile.title}</h1>
                  <p>
                    <b>Link: </b>
                    <a
                      href={profile.linkedInUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {profile.linkedInUrl}
                    </a>
                  </p>
                  <p>
                    <b>Number of emploers: </b>
                    {profile.numOfEmpl}
                  </p>
                </div>
              </div>
            );
        }else if(profile.finstatUrl){
          return (
            <div
              key={indx}
              className={`${indx} profile`}
              onClick={chooseProfile}
            >
              <div className="profile-info">
                <h1>{profile.finstatTitle}</h1>
                <p>
                  <b>Link: </b>
                  <a href={profile.finstatUrl} target="_blank" rel="noreferrer">
                    {profile.finstatUrl}
                  </a>
                </p>
                <p>
                  <b>ICO: </b>
                  {profile.ico}
                </p>
              </div>
            </div>
          );
        }else if (profile.growjoUrl) {
          return (
            <div
              key={indx}
              className={`${indx} profile`}
              onClick={chooseProfile}
            >
              <div className="profile-image">
                <img
                  src={
                    profile.growjoPicture
                      ? profile.growjoPicture
                      : "./growjo.png"
                  }
                  alt="avatar"
                />
              </div>
              <div className="profile-info">
                <h1>{profile.growjoTitle}</h1>
                <p>
                  <b>Link: </b>
                  <a href={profile.growjoUrl} target="_blank" rel="noreferrer">
                    {profile.growjoUrl}
                  </a>
                </p>
                <p>
                  <b>Snippet: </b>
                  {profile.growjoSnippet}
                </p>
              </div>
            </div>
          );
        }
        })
      }
      </div>
    </Modal>
  );
}

export default CompanyProfiles;