import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function Loading({loading}){
  return (
    <Modal
      isOpen={loading.loading}
      className="loading"
      overlayClassName="overlay"
    >
      <img src="/loading.gif" />
    </Modal>
  );
}

export default Loading;