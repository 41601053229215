import React, {useState} from 'react';

function ArrayInput({entity, emptyObj, keys, placeholders}){
  const [newObject, setNewObject] = useState({...emptyObj});

  // const forceUpdate = React.useReducer(() => ({}))[1];

  const handleDelete = (indx) => {
    let updatedEducation = [...entity.objects];
    updatedEducation.splice(indx, 1);
    entity.setObjects(updatedEducation);
  };

  const handleUpdate = (event) => {
    let updatedObjects = [...entity.objects];
    if(event.target.type === "checkbox"){
      const indx = event.target.className;
      updatedObjects[indx]["addToFinal"] =
        !updatedObjects[indx]["addToFinal"];
    }else if(event.target.type === "radio"){
      const indx = parseInt(event.target.className);
      updatedObjects = updatedObjects.map((obj,i) => {
        obj["currentPosition"] = i === indx ? true : false;
        return obj;
      });
    }else{
      const classes = event.target.className.split(" ");
      const indx = classes[0];
      const key = classes[1];
      const newValue = event.target.value;
      if (/Date/.test(key)) {
        console.log(newValue);
      }
      updatedObjects[indx][key] = newValue;
    }
    entity.setObjects(updatedObjects);
  };

  const handleSettingNew = (event) => {
    const classes = event.target.className.split(" ");
    const key = classes[1];
    const newValue = event.target.value;
    const currentObject = {...newObject};
    currentObject[key] = newValue;
    setNewObject(currentObject);
  };

  const handleAdd = () => {
    const currentObjects = [...entity.objects]
    currentObjects.push(newObject);
    if(newObject.hasOwnProperty("startDate") &&
      newObject.hasOwnProperty("endDate")){
      currentObjects.sort((a, b) => {
        if (a.endDate && b.endDate) {
          return new Date(b.endDate) - new Date(a.endDate);
        } else if (a.endDate) {
          return 1;
        } else if (b.endDate) {
          return -1;
        } else {
          return new Date(b.startDate) - new Date(a.startDate);
        }
      });
    }
    entity.setObjects(currentObjects);
    setNewObject({...emptyObj});
  };

  return(
    <>
    {keys.map( (key, i) => {
      return (
        <input
          type={/Date/.test(key) ? "date" : "text"}
          value={newObject[key]}
          className={`addNew ${key}`}
          onChange={(event) => handleSettingNew(event)}
          placeholder={placeholders[i]}
          key={i}
        />
      );
    })}
    <button onClick={handleAdd}>Add new</button>
    {
      entity.objects.map( (entity, indx) => {
        return (
          <div className="school" key={indx}>
            {keys.map((key, i) => {
              return (
                <input
                  type={/Date/.test(key) ? "date" : "text"}
                  value={entity[key]}
                  className={`${indx} ${key}`}
                  onChange={(event) => handleUpdate(event)}
                  placeholder={placeholders[i]}
                  key={i}
                />
              );
            })}
            <button
              className={indx}
              onClick={(event) => handleDelete(event.target.className)}
            >
              Delete
            </button>
            <label className="addToFinal">
              Add to the final document:
              <input type="checkbox" checked={entity["addToFinal"]} className={indx} onClick={(event) => handleUpdate(event)}/>
            </label>
            {entity.hasOwnProperty("endDate") && !entity.endDate ? (
              <label className="markCurrent">
                Mark as current position:
                <input type="radio" name="currentPosition" checked={entity.currentPosition} onChange={(event) => handleUpdate(event)} className={indx}/>
              </label>
            ) : ("")}
          </div>
        );
      })
    }
    </>
  );
}

export default ArrayInput;