import React, {useState, useEffect} from "react";
import { Link, Routes, Route, useNavigate, useParams } from "react-router-dom";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from "@react-oauth/google";
import TitleInput from "./TitleInput";
import axios from "axios";
import Loading from "./Loading.js"; 
import ResponseModal from "./ResponseModal";
import CompanyDataForm  from "./CompanyDataForm";
import DatabaseProfiles from "./DatabaseProfiles";


function Company({apiLink}){
  const [linkedInSearch, setLinkedInSearch] = useState({
    title: "",
    indx: "",
    profile: {},
  });
  const [finstatSearch, setFinstatSearch] = useState({
    title: "",
    indx: "",
    profile: {}
  });
  const [growjoSearch, setGrowjoSearch] = useState({
    title: "",
    indx: "",
    profile: {},
  });
  // const [dbProfile, setDbProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [dbSearch, setDbSearch] = useState(false);

  const [dbObjId, setDbObjId] = useState("");
  const [title, setTitle] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [finstatUrl, setFinstatUrl] = useState("");
  const [growjoUrl, setGrowjoUrl] = useState("");
  const [foundedDate, setFoundedDate] = useState("");
  const [about, setAbout] = useState("");
  const [website, setWebsite] = useState("");
  const [headquarters, setHeadquarters] = useState("");
  const [industries, setIndustries] = useState("");
  const [numOfEmpl, setNumOfEmpl] = useState("");
  const [topEmpl, setTopEmpl] = useState([]);
  const [statsDate, setStatesDate] = useState("");
  const [revenue, setRevenue] = useState("");
  const [valuation, setValuation] = useState("");
  const [loss, setLoss] = useState("");
  const [profit, setProfit] = useState("");
  const [assets, setAssets] = useState("");
  const [capital, setCapital] = useState("");
  const [debt, setDebt] = useState("");
  const [margin, setMargin] = useState("");
  const [establishment, setEstablishment] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [activity, setActivity] = useState("");
  const [observations, setObservations] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const scrapeProfile = (dbProfile) => {
    setLoading(true);
    console.log(dbProfile);
    if (!linkedInSearch.profile._id && !dbProfile._id) {
      console.log("making search");
      console.log(linkedInSearch.profile._id);
      const mainReq = `${apiLink}/api/company/`;
      axios
        .post(mainReq, {
          indx: linkedInSearch.indx,
          title: linkedInSearch.title,
          growjoUrl: growjoSearch.profile.growjoUrl,
          finstatUrl: finstatSearch.profile.finstatUrl,
        })
        .then(async (res) => {
          console.log(res);
          setProfile(res.data);
          setLoading(false);
          navigate("profile");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (dbProfile) {
      setProfile(dbProfile);
      setGrowjoUrl(dbProfile.growjoUrl);
      setFinstatUrl(dbProfile.finstatUrl);
      setLoading(false);
      navigate("profile");
    } else {
      setProfile(linkedInSearch.profile);
      setLoading(false);
      navigate("profile");
    }
  }

  const setProfile = async (data) => {
    if(data._id){
      setDbObjId(data._id);
      setCompanyLogo(data.companyLogo);
      setTitle(data.title);
    }else{
      await toDataUrl(linkedInSearch.profile.companyLogo, linkedInSearch.title);
      console.log("Setting profile...");
      if(linkedInSearch.title){
        setTitle(linkedInSearch.title);
      }else if(finstatSearch.title){
        setTitle(finstatSearch.title);
      }else{
        setTitle(growjoSearch.title);
      }
    }
    setLinkedInUrl(data.linkedInUrl);
    if (growjoSearch.profile.growjoUrl){
      setGrowjoUrl(growjoSearch.profile.growjoUrl);
    }
    if (finstatSearch.profile.finstatUrl){
      setFinstatUrl(finstatSearch.profile.finstatUrl);
    }
    setFoundedDate(data.foundedDate);
    setAbout(data.about);
    setWebsite(data.website);
    setHeadquarters(data.headquarters);
    setIndustries(data.industries);
    setNumOfEmpl(data.numOfEmpl);
    setTopEmpl(data.topEmpl);
    if (data.statsDate) {
      setStatesDate(data.statsDate);
    }
    if (data.revenue) {
      setRevenue(data.revenue);
    }
    if (data.valuation) {
      setValuation(data.valuation);
    }
    if (data.loss) {
      setLoss(data.loss);
    }
    if (data.profit) {
      setProfit(data.profit);
    }
    if (data.assets) {
      setAssets(data.assets);
    }
    if (data.capital) {
      setCapital(data.capital);
    }
    if (data.debt) {
      setDebt(data.debt);
    }
    if (data.margin) {
      setMargin(data.margin);
    }
    if (data.establishment) {
      setEstablishment(data.establishment);
    }
    if(data.email){
      setEmail(data.email);
    }
    if(data.phone){
      setPhone(data.phone);
    }
  }

  const uploadProfile = () => {
    setLoading(true);
    console.log(dbObjId);
    const profileUploadReq = `${apiLink}/api/company/profiles`;
    const bodyFormData = new FormData();
    bodyFormData.append("id", dbObjId);
    bodyFormData.append("title", title);
    bodyFormData.append("linkedInUrl", linkedInUrl);
    bodyFormData.append("companyLogo", companyLogo);
    bodyFormData.append("fistatUrl", finstatUrl);
    bodyFormData.append("growjoUrl", growjoUrl);
    bodyFormData.append("foundedDate", foundedDate);
    bodyFormData.append("about", about);
    bodyFormData.append("website", website);
    bodyFormData.append("headquarters", headquarters);
    bodyFormData.append("industries", industries);
    bodyFormData.append("numOfEmpl", numOfEmpl);
    bodyFormData.append("statsDate", statsDate);
    bodyFormData.append("revenue", revenue);
    bodyFormData.append("valuation", valuation);
    bodyFormData.append("loss", loss);
    bodyFormData.append("profit", profit);
    bodyFormData.append("assets", assets);
    bodyFormData.append("capital", capital);
    bodyFormData.append("debt", debt);
    bodyFormData.append("margin", margin);
    bodyFormData.append("establishment", establishment);
    bodyFormData.append("productDetails", productDetails);
    bodyFormData.append("activity", activity);
    bodyFormData.append("observations", observations);
    bodyFormData.append("growjoSnippet", growjoSearch.profile.growjoSnippet);
    bodyFormData.append("email", email);
    bodyFormData.append("phone", phone);
    bodyFormData.append(
      "topEmpl",
      JSON.stringify(topEmpl.map((empl) => JSON.stringify(empl)))
    );
    console.log(bodyFormData);
    axios
      .post(profileUploadReq, bodyFormData, {})
      .then(function (response) {
        response["action"] = dbObjId ? "updated" : "created";
        setLoading(false);
        setResponse(response);
        console.log(response);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        setResponse(error);
      });
  }

  const deleteProfile = async (event) => {
    let id = "";
    if(dbSearch){
      const classes = event.target.className.split(" ");
      id = classes[0];
    }else{
      id = dbObjId;
    }
    const deleteLink = `${apiLink}/api/company/profiles?id=${id}`;
    await axios
      .delete(deleteLink)
      .then((res) => {
        console.log(res.data.action);
        setLinkedInSearch({
          title: "",
          indx: "",
          profile: {},
        });
        setFinstatSearch({
          title: "",
          indx: "",
          profile: {},
        });
        setGrowjoSearch({
          title: "",
          indx: "",
          profile: {},
        });
        if(!dbSearch){
          res["action"] = "deleted";
          setResponse(res);
        }
        setDbObjId("");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toDataUrl = async (url, title) => {
    if (!url) {
      url = "/default-company.png";
    }
    await fetch(url)
      .then(async (response) => {
        console.log(response);
        return response.blob();
      })
      .then(async (blob) => {
        let format = blob.type;
        format = format.split("/");
        format = format[format.length - 1];
        const profilePicture = new File([blob], `${title}.${format}`, {
          type: blob.type,
        });
        setCompanyLogo(profilePicture);
      });
  };

  const updateCompanyLogo = (event) => {
    console.log("updated")
    setCompanyLogo(event.target.files[0]);
  } 

  const handleBackMain = async () => {
    setResponse("");
    navigate("/company");
  };

  const logout = async () => {
    localStorage.clear();
    googleLogout();
  };

  return (
    <div id="main">
      <Routes>
        <Route
          path={""}
          element={
            <>
              {dbSearch ? (
                <DatabaseProfiles
                  type={"company"}
                  dbSearch={{dbSearch, setDbSearch}}
                  apiLink={apiLink}
                  deleteProfile={deleteProfile}
                  scrapeProfile={scrapeProfile}
                />
              ) : (
                <TitleInput
                  apiLink={apiLink}
                  linkedInSearch={{ linkedInSearch, setLinkedInSearch }}
                  finstatSearch={{ finstatSearch, setFinstatSearch }}
                  growjoSearch={{ growjoSearch, setGrowjoSearch }}
                  logout={logout}
                  scrapeProfile={scrapeProfile}
                  loading={{ loading, setLoading }}
                  responseModal={{ response, setResponse }}
                  dbObjId={{ dbObjId, setDbObjId }}
                  companyLogo={{ companyLogo }}
                  dbSearch={{dbSearch, setDbSearch}}
                />
              )}
            </>
          }
        />
        <Route
          path={"profile"}
          element={
            <CompanyDataForm
              dbObjId={{ dbObjId, setDbObjId }}
              companyLogo={{ companyLogo, updateCompanyLogo }}
              title={{ title, setTitle }}
              linkedInUrl={{ linkedInUrl, setLinkedInUrl }}
              growjoUrl={{ growjoUrl, setGrowjoUrl }}
              finstatUrl={{ finstatUrl, setFinstatUrl }}
              foundedDate={{ foundedDate, setFoundedDate }}
              about={{ about, setAbout }}
              website={{ website, setWebsite }}
              headquarters={{ headquarters, setHeadquarters }}
              industries={{ industries, setIndustries }}
              numOfEmpl={{ numOfEmpl, setNumOfEmpl }}
              statsDate={{ statsDate, setStatesDate }}
              revenue={{ revenue, setRevenue }}
              valuation={{ valuation, setValuation }}
              loss={{ loss, setLoss }}
              profit={{ profit, setProfit }}
              assets={{ assets, setAssets }}
              capital={{ capital, setCapital }}
              debt={{ debt, setDebt }}
              margin={{ margin, setMargin }}
              establishment={{ establishment, setEstablishment }}
              topEmpl={{ objects: topEmpl, setObjects: setTopEmpl }}
              productDetails={{
                markdown: productDetails,
                setMarkdown: setProductDetails,
              }}
              activity={{ markdown: activity, setMarkdown: setActivity }}
              observations={{
                markdown: observations,
                setMarkdown: setObservations,
              }}
              email={{email, setEmail}}
              phone={{phone, setPhone}}
              uploadProfile={uploadProfile}
              deleteProfile={deleteProfile}
            />
          }
        />
      </Routes>
      {loading ? <Loading loading={{ loading, setLoading }} /> : ""}
      {response ? (
        <ResponseModal
          response={{ response, setResponse }}
          handleBackMain={handleBackMain}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Company;