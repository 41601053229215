import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

function ResponseModal({response}){
  const closeModal = () => {
    response.setResponse("");
  }
  
  return (
    <Modal
      isOpen={response.response ? true : false}
      className="response"
      overlayClassName="overlay"
    >
      {response.response.status === 200 ? (
        <p>
          {" "}
          Profile for {response.response.data.name} has been successfully{" "}
          {response.response.action}!
        </p>
      ) : response.response.status === 404 ? (
        <>
          <p>{response.response.message}</p>
        </>
      ) : (
        <>
          <p> Something went wrong!</p>
          <p>{response.response.message}</p>
        </>
      )}
      <div className="btns">
        <Link to={`/`}>Main</Link>
        <button onClick={closeModal}>Close</button>
      </div>
    </Modal>
  );
}

export default ResponseModal;