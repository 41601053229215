import React, {useState} from "react";
import Moment from "moment";
import { marked } from "marked";
import './pdf.css'

export const TemplateToPrint = React.forwardRef((props, ref) => {
  const [expOnCurrent] = useState(() =>{
    if(props.data.currentPosition.length && props.data.currentPosition[0].startDate){
      const start = new Date(props.data.currentPosition[0].startDate);
      const end = new Date();
      const experience = Math.round((end - start) / (1000 * 60 * 60 * 24));
      return `, ${(experience/365).toFixed(1)}`;
    }
    return "";
  }
  );

  const getExp = (start, end) => {
    if(start){
      start = new Date(start);
      end = end ? new Date(end) : new Date();
      const experience = Math.round((end - start) / (1000 * 60 * 60 * 24));
      return `, ${(experience / 365).toFixed(1)}`;
    }
    return "";
  }


  return (
    <div ref={ref} className="pdf">
      <div className="pdf-header">
        <h1>{props.data.name}</h1>
        <img
          src={
            typeof props.data.picture === "object"
              ? URL.createObjectURL(props.data.picture)
              : props.data.picture
          }
          alt="avatar"
        />
      </div>
      <div className="pdf-description">{props.data.briefIntro}</div>
      <table className="pdf-main">
        <tr>
          <td className="pdf-basic">
            <div>
              <h2>Basic overview</h2>
              <ul>
                <li>
                  <b>Age</b>: {props.data.age}
                </li>
                <li>
                  <b>City</b>: {props.data.location}
                </li>
                <li>
                  <b>Professional Experience</b>: {props.data.profExp}
                </li>
                <li>
                  <b>Industry</b>: {props.data.industry}
                </li>
                <li>
                  <b>Specialties</b>: {props.data.specialties}
                </li>
              </ul>
            </div>
          </td>
          <td className="pdf-education">
            <div>
              <h2>Educational Experience</h2>
              <ul>
                {props.data.education.map((school, key) => {
                  const degree = school.degree ? `, ${school.degree}` : "";
                  const field = school.field ? `, ${school.field}` : "";
                  return (
                    <li key={key}>
                      {school.startYear && school.endYear
                        ? `${school.startYear} - ${school.endYear} ${school.school}` +
                          degree +
                          field
                        : `${school.school}` + degree + field}
                    </li>
                  );
                })}
                <li>
                  {" "}
                  Languages spoken:
                  <ul>
                    {props.data.languages.map((lang, key) => {
                      const proficiency = lang.proficiency
                        ? `, ${lang.proficiency}`
                        : "";
                      return <li key={key}>{`${lang.name}` + proficiency}</li>;
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td className="pdf-experience">
            <div>
              <h2>Work Experience</h2>
              <ul>
                {props.data.currentPosition.length ? (
                  <>
                    <li>
                      <b>Company: </b>{" "}
                      {props.data.currentPosition[0].companyName}
                    </li>
                    <li>
                      <b>Job Title: </b> {props.data.currentPosition[0].title}{" "}
                      {expOnCurrent}
                    </li>
                  </>
                ) : (
                  ""
                )}
                <li>
                  <b>Total experience:</b> {props.data.totalExp}
                </li>
                {props.data.experience.length ? (
                  <li>
                    <b>Previous experience:</b>
                    <ul>
                      {props.data.experience.map((position, key) => {
                        const title = position.title
                          ? `, ${position.title}`
                          : "";
                        const exp = getExp(
                          position.startDate,
                          position.endDate
                        );
                        let company = "";
                        if (position.companyURL) {
                          company = (
                            <a href={position.companyURL}>
                              {position.companyName}
                            </a>
                          );
                        } else {
                          company = (
                            <p style={{ display: "inline-block" }}>
                              {position.companyName}
                            </p>
                          );
                        }
                        return (
                          <li key={key}>
                            {company}
                            {title}
                            {exp}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </td>
          <td className="pdf-socials">
            <div>
              <h2>Social media</h2>
              <li>
                <a href={props.data.linkedInUrl}>LinkedIn</a>
              </li>
            </div>
          </td>
        </tr>
        {props.data.interestinFacts && props.data.activity ? (
          <tr>
            <td className="pdf-facts">
              <div>
                <h2>Interesting facts</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(props.data.interestinFacts),
                  }}
                ></div>
              </div>
            </td>
            <td className="pdf-activity">
              <div>
                <h2>Activity and memberships</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(props.data.activity),
                  }}
                ></div>
              </div>
            </td>
          </tr>
        ) : (
          ""
        )}
        <tr>
          <td className="pdf-contacts" colSpan={2}>
            <h2>Contacts | </h2>
            <b>email:</b> {props.data.email}&nbsp;&nbsp;&nbsp;<b>phone:</b>{" "}
            {props.data.phone}
          </td>
        </tr>
      </table>
      <div className="pdf-footer">
        SCALEUP | Person Onesheeter -{" "}
        {props.data.name.split(" ").length > 2
          ? props.data.name.split(" ").slice(0, 2).join(" ")
          : props.data.name}{" "}
        - <b>person overview |</b> Last edit: {Moment().format("Do MMMM YY")} |{" "}
        {props.data.user.split("@").slice(0, 1).join("").toUpperCase()}
      </div>
    </div>
  );
});