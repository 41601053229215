import React, {useState} from "react";
import axios from "axios";
import Profiles from "./Profiles.js";

function NameInput({
  apiLink,
  linkedInSearch,
  twitterSearch,
  scrapeProfile,
  loading,
  dbSearch,
}) {
  const [linkedInProfiles, setLinkedInProfiles] = useState([]);
  const [twitterProfiles, setTwitterProfiles] = useState([]);
  const [modalState, setModalState] = useState("");

  const findLinkedInProfiles = () => {
    if (!linkedInSearch.linkedInSearch.name) {
      return false;
    }
    loading.setLoading(true);
    const linkProfilesReq = `${apiLink.apiLink}/api/person/profiles/linkedin?name=${linkedInSearch.linkedInSearch.name}`;
    axios
      .get(linkProfilesReq)
      .then((res) => {
        console.log(res);
        setLinkedInProfiles(res.data.profiles);
        if (res.data.profiles.length) {
          setModalState("linkedin");
        }
        loading.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        loading.setLoading(false);
      });
  };

  const findTwitterProfiles = () => {
    if (!twitterSearch.twitterSearch.name) {
      return false;
    }
    loading.setLoading(true);
    setModalState("twitter");
    const linkProfilesReq = `${apiLink.apiLink}/api/person/profiles/twitter?name=${twitterSearch.twitterSearch.name}`;
    axios
      .get(linkProfilesReq)
      .then((res) => {
        setTwitterProfiles(res.data.profiles);
        if (res.data.profiles.length) {
          setModalState("twitter");
        }
        loading.setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        loading.setLoading(false);
      });
  };

  const updateName = (event) => {
    if (event.target.className === "linkedIn") {
      const newObject = { ...linkedInSearch.linkedInSearch };
      if (!event.target.value.length) {
        setLinkedInProfiles([]);
      }
      newObject["name"] = event.target.value;
      linkedInSearch.setLinkedInSearch(newObject);
    } else if (event.target.className === "twitter") {
      const newObject = { ...twitterSearch.twitterSearch };
      if (!event.target.value.length) {
        setTwitterProfiles([]);
      }
      newObject["name"] = event.target.value;
      twitterSearch.setTwitterSearch(newObject);
    }
  };

  const removePreview = (event) => {
    if (event.target.className.split(" ")[0] === "linkedin") {
      const newObject = { ...linkedInSearch.linkedInSearch };
      newObject.profile = {};
      newObject.name = "";
      linkedInSearch.setLinkedInSearch(newObject);
    } else {
      const newObject = { ...twitterSearch.twitterSearch };
      newObject.profile = {};
      newObject.name = "";
      twitterSearch.setTwitterSearch(newObject);
    }
  };

  const editPreview = (event) => {
    removePreview(event);
    if (event.target.className.split(" ")[0] === "linkedin") {
      setModalState("linkedin");
    } else {
      const newObject = { ...linkedInSearch.linkedInSearch };
      setModalState("twitter");
    }
  };

  const getPosition = () => {
    const position = linkedInSearch.linkedInSearch.profile.position
      ? linkedInSearch.linkedInSearch.profile.position
      : linkedInSearch.linkedInSearch.profile.experience &&
        linkedInSearch.linkedInSearch.profile.experience[0]
      ? `${linkedInSearch.linkedInSearch.profile.experience[0].title} - ${linkedInSearch.linkedInSearch.profile.experience[0].companyName}`
      : "";
    return position;
  };

  const openDatabase = () => {
    dbSearch.setDbSearch(true);
  };

  return (
    <div className="input-name">
      {linkedInSearch.linkedInSearch.profile.name ? (
        <div className="search-preview">
          <img
            src={
              linkedInSearch.linkedInSearch.profile.picture
                ? linkedInSearch.linkedInSearch.profile.picture
                : "/default.png"
            }
            alt="avatar"
          />
          <div className="profile-info">
            <h1>{linkedInSearch.linkedInSearch.profile.name}</h1>
            <p>
              <b>Link: </b>
              <a
                href={linkedInSearch.linkedInSearch.profile.linkedInUrl}
                target="_blank"
              >
                {linkedInSearch.linkedInSearch.profile.linkedInUrl}
              </a>
            </p>
            <p>
              <b>Position: </b>
              {getPosition()}
            </p>
          </div>
          <div className="actions">
            <i
              className="linkedin fa fa-pencil-square-o"
              aria-hidden="true"
              onClick={editPreview}
            ></i>
            <i
              className="linkedin fa fa-trash"
              aria-hidden="true"
              onClick={removePreview}
            ></i>
          </div>
        </div>
      ) : (
        <div className="search">
          <img src="./linkedin.png" />
          <input
            type="text"
            value={linkedInSearch.linkedInSearch.name}
            className="linkedIn"
            onChange={(event) => updateName(event)}
            placeholder="Enter a name"
          />
          <i
            className={
              linkedInSearch.linkedInSearch.name
                ? `fa fa-search`
                : `fa fa-search disabled`
            }
            onClick={findLinkedInProfiles}
            aria-hidden="true"
          ></i>
        </div>
      )}
      {twitterSearch.twitterSearch.profile.twitterName ? (
        <div className="search-preview">
          <img
            src={
              twitterSearch.twitterSearch.profile.twitterPicture
                ? twitterSearch.twitterSearch.profile.twitterPicture
                : "/default.png"
            }
            alt="avatar"
          />
          <div className="profile-info">
            <h1>{twitterSearch.twitterSearch.profile.twitterName}</h1>
            <p>
              <b>Username: </b>
              {twitterSearch.twitterSearch.profile.twitterUsername}
            </p>
            <p>
              <b>Link: </b>
              <a href={twitterSearch.twitterSearch.profile.twitterUrl}>
                {twitterSearch.twitterSearch.profile.twitterUrl}
              </a>
            </p>
            <p>
              <b>Followers: </b>
              {twitterSearch.twitterSearch.profile.twitterFollowers}
            </p>
          </div>
          <div className="actions">
            <i
              className="twitter fa fa-pencil-square-o"
              aria-hidden="true"
              onClick={editPreview}
            ></i>
            <i
              className="twitter fa fa-trash"
              aria-hidden="true"
              onClick={removePreview}
            ></i>
          </div>
        </div>
      ) : (
        <div className="search">
          <img src="./twitter.png" />
          <input
            type="text"
            value={twitterSearch.twitterSearch.name}
            className="twitter"
            onChange={(event) => updateName(event)}
            placeholder="Enter a name"
          />
          <i
            className={
              twitterSearch.twitterSearch.name
                ? `fa fa-search`
                : `fa fa-search disabled`
            }
            onClick={findTwitterProfiles}
            aria-hidden="true"
          ></i>
        </div>
      )}
      <Profiles
        modalState={{ modalState, setModalState }}
        profiles={
          modalState === "linkedin" ? linkedInProfiles : twitterProfiles
        }
        linkedInSearch={linkedInSearch}
        twitterSearch={twitterSearch}
      />
      <div className="scrape-btn" onClick={scrapeProfile}>
        <div>
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
        Scrape
      </div>
      <div className="scrape-btn" onClick={openDatabase}>
        <div>
          <i className="fa fa-database" aria-hidden="true"></i>
        </div>
        Database
      </div>
    </div>
  );
}

export default NameInput;
