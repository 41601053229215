import React, {useRef,  useState} from "react";
import ArrayInput from './ArrayInput.js';
import MarkedInput from "./MarkdownInput.js";
import { useReactToPrint } from "react-to-print";
import { TemplateToPrint } from "./TemplateToPrint.js";
import {marked} from "marked";
// import { jsPDF } from "jspdf";
// const doc = new jsPDF();

function DataForm({ dbObjId, linkedInUrl, name, age, picture, briefIntro, education, languages, skills, experience, totalExp, uploadProfile, location, profExp, industry, specialties, facts, activity, twitterUrl, twitterFollowers, email, phone, deleteProfile, user }) {
  const [pdfData, setPdfData] = useState({
    name: name.name,
    picture: picture.picture,
    age: age.age,
    location: location.location,
    profExp: profExp.profExp,
    industry: industry.industry,
    specialities: specialties.specialities,
    briefIntro: briefIntro.briefIntro,
    education: education.objects,
    languages: languages.objects,
    currentPosition: experience.objects.filter(position => position.currentPosition),
    experience: experience.objects,
    totalExp: totalExp.totalExp,
    linkedInUrl: linkedInUrl.linkedInUrl,
    interestinFacts: marked.parse(facts.markdown),
    activity: marked.parse(activity.markdown),
    emai: email.email,
    phone: phone.phone,
    user: user.email
  })

  const componentRef = useRef();

  const print = async () => {
    const newPdfData = {
      name: name.name,
      picture: picture.picture,
      age: age.age,
      location: location.location,
      profExp: profExp.profExp,
      industry: industry.industry,
      specialties: specialties.specialties,
      briefIntro: briefIntro.briefIntro,
      education: education.objects.filter((school) => school.addToFinal),
      languages: languages.objects.filter((lang) => lang.addToFinal),
      currentPosition: experience.objects.filter(
        (position) => position.currentPosition
      ),
      experience: experience.objects.filter(
        (position) => position.addToFinal && !position.currentPosition
      ),
      totalExp: totalExp.totalExp,
      linkedInUrl: linkedInUrl.linkedInUrl,
      interestinFacts: marked.parse(facts.markdown),
      activity: marked.parse(activity.markdown),
      email: email.email,
      phone: phone.phone,
      user: user.email
    };
    console.log(newPdfData);
    await setPdfData(newPdfData);
    handlePrint();
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="scraped data">
      <label>Profile picture</label>
      <img
        src={
          typeof picture.picture === "object"
            ? URL.createObjectURL(picture.picture)
            : picture.picture
        }
        className="profile-picture"
        alt="Profile avatar"
      />
      <input type="file" onChange={picture.updatePicture} />
      <label>Full name</label>
      <input
        type="text"
        value={name.name}
        onChange={(event) => name.setName(event.target.value)}
      />

      <label>LinkedIn URL:</label>
      <input
        type="text"
        value={linkedInUrl.linkedInUrl}
        onChange={(event) => linkedInUrl.setLinkedInUrl(event.target.value)}
      />

      <label>Twitter Url:</label>
      <input
        type="text"
        value={twitterUrl.twitterUrl}
        onChange={(event) => twitterUrl.setTwitterUrl(event.target.value)}
      />

      <label>Twitter Followers:</label>
      <input
        type="text"
        value={twitterFollowers.twitterFollowers}
        onChange={(event) =>
          twitterFollowers.setTwitterFollowers(event.target.value)
        }
      />

      <label>Age:</label>
      <input
        type="text"
        value={age.age}
        onChange={(event) => age.setAge(event.target.value)}
      />

      <label>Location:</label>
      <input
        type="text"
        value={location.location}
        onChange={(event) => location.setLocation(event.target.value)}
      />

      <label>Brief Intro:</label>
      <textarea
        type="text"
        value={briefIntro.briefIntro}
        onChange={(event) => briefIntro.setIntro(event.target.value)}
      ></textarea>

      <label>Professional Experience:</label>
      <textarea
        type="text"
        value={profExp.profExp}
        onChange={(event) => profExp.setProfExp(event.target.value)}
      ></textarea>

      <label>Industry:</label>
      <input
        type="text"
        value={industry.industry}
        onChange={(event) => industry.setIndustry(event.target.value)}
      />

      <label>Specialties:</label>
      <textarea
        type="text"
        value={specialties.specialties}
        onChange={(event) => specialties.setSpecialties(event.target.value)}
      ></textarea>

      <label>Total experience:</label>
      <input
        type="text"
        value={totalExp.totalExp}
        onChange={(event) => totalExp.setTotalExp(event.target.value)}
      />

      <label>Email:</label>
      <input
        type="text"
        value={email.email}
        onChange={(event) => email.setEmail(event.target.value)}
      />

      <label>Phone:</label>
      <input
        type="text"
        value={phone.phone}
        onChange={(event) => phone.setPhone(event.target.value)}
      />

      <label>Interesting facts</label>
      <MarkedInput markdown={facts} />

      <label>Activity and memberships</label>
      <MarkedInput markdown={activity} />

      {experience.objects ? (
        <fieldset>
          <label>Experience:</label>
          <ArrayInput
            entity={experience}
            emptyObj={{
              companyName: "",
              companyURL: "",
              title: "",
              startDate: "",
              endDate: "",
              addToFinal: true,
              currentPosition: false,
            }}
            keys={[
              "companyName",
              "companyURL",
              "title",
              "startDate",
              "endDate",
            ]}
            placeholders={[
              "Company name",
              "Company URL",
              "Position",
              "Start Date",
              "End Date",
            ]}
          />
        </fieldset>
      ) : (
        ""
      )}

      {education.objects ? (
        <fieldset>
          <label>Education:</label>
          <ArrayInput
            entity={education}
            emptyObj={{
              school: "",
              degree: "",
              field: "",
              startYear: "",
              endYear: "",
              addToFinal: true,
            }}
            keys={["school", "degree", "field", "startYear", "endYear"]}
            placeholders={[
              "School name",
              "Degree",
              "Field",
              "Start Year",
              "End Year",
            ]}
          />
        </fieldset>
      ) : (
        ""
      )}

      {languages.objects ? (
        <fieldset>
          <label>Languages:</label>
          <ArrayInput
            entity={languages}
            emptyObj={{
              name: "",
              proficiency: "",
              addToFinal: true,
            }}
            keys={["name", "proficiency"]}
            placeholders={["Language", "Proficiency"]}
          />
        </fieldset>
      ) : (
        ""
      )}

      {skills.objects ? (
        <fieldset>
          <label>Skills:</label>
          <ArrayInput
            entity={skills}
            emptyObj={{
              name: "",
            }}
            keys={["name"]}
            placeholders={["Skill name"]}
          />
        </fieldset>
      ) : (
        ""
      )}

      <div style={{ display: "none" }}>
        <TemplateToPrint ref={componentRef} data={pdfData} />
      </div>

      <button className="sumbit-profile" onClick={uploadProfile}>
        {dbObjId ? "Update profile" : "Create profile"}
      </button>
      {dbObjId ? (
        <button className="delete-profile" onClick={deleteProfile}>
          Delete profile
        </button>
      ) : (
        ""
      )}
      <button className="sumbit-profile" onClick={print}>
        To PDF
      </button>
    </div>
  );
}

export default DataForm;

