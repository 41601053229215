import React from "react";


function MarkedInput({markdown}){

  return (
    <>
      <textarea id="editor" value={markdown.markdown} onChange={(event) => markdown.setMarkdown(event.target.value)}>
      </textarea>
    </>
  );
}

export default MarkedInput;