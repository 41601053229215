import React, {useState, useEffect} from "react";
import axios from "axios";

function DatabaseProfiles({
  type,
  dbSearch,
  apiLink,
  deleteProfile,
  scrapeProfile,
}) {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    getProfiles();
  }, []);

  const handleDelete = async (event) => {
    await deleteProfile(event);
    getProfiles();
  };

  const getProfiles = () => {
    const dbProfilesReq = `${apiLink}/api/database?type=${type}`;
    axios
      .get(dbProfilesReq)
      .then((res) => {
        console.log(res.data);
        setProfiles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBack = () => {
    dbSearch.setDbSearch(false);
  };

  const handleEdit = (event) => {
    const classes = event.target.className.split(" ");
    const id = classes[0];
    // dbProfile.setDbProfile(profiles[id]);
    scrapeProfile(profiles[id]);
    // console.log(profiles[id]);
  };

  return (
    <div className="db">
      <h1 className="db-title">
        {type === "company" ? "Company" : "Personal"} profiles in the database:
      </h1>
      <div className="db-profiles">
        {profiles.map((profile, key) => {
          return (
            <div className="profile" key={key}>
              <img
                src={type === "company" ? profile.companyLogo : profile.picture}
              />
              <div className="info">
                {profile.name ? (
                  <h2>{profile.name}</h2>
                ) : (
                  <h2>{profile.title}</h2>
                )}
                <p>
                  <b>LinkedIn URL:</b>
                  <a href={profile.linkedInUrl} target="_blank">
                    {profile.linkedInUrl}
                  </a>
                </p>
                {profile.finstatUrl ? (
                  <p>
                    <b>Finstat URL: </b>
                    <a href={profile.finstatUrl} target="_blank">
                      {profile.finstatUrl}
                    </a>
                  </p>
                ) : profile.growjoUrl ? (
                  <p>
                    <b>Growjo URL: </b>
                    <a href={profile.growjoUrl} target="_blank">
                      {profile.growjoUrl}
                    </a>
                  </p>
                ) : (
                  ""
                )}
                {profile.website ? (
                  <p>
                    <b>Website: </b>
                    <a href={profile.website} target="_blank">
                      {profile.website}
                    </a>
                  </p>
                ) : (
                  ""
                )}
                {profile.twitterUrl ? (
                  <p>
                    <b>Twitter Url: </b>
                    <a href={profile.twitterUrl} target="_blank">
                      {profile.twitterUrl}
                    </a>
                  </p>
                ) : (
                  ""
                )}
                {profile.location ? (
                  <p>
                    <b>Location: </b>
                    {profile.location}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="actions">
                <i
                  className={`${key} fa fa-pencil-square-o`}
                  onClick={handleEdit}
                  aria-hidden="true"
                ></i>
                <i
                  className={`${profile._id} fa fa-trash-o`}
                  onClick={handleDelete}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          );
        })}
      </div>
      <button onClick={handleBack}>Back</button>
    </div>
  );
}

export default DatabaseProfiles;